// 客户等级
import request from '@/utils/request'
// 获取商品列表
export function clientlevelList(data) {
  return request({
    url: '/baseAdmin/customer-class/index',
    method: 'post',
    data,
  })
}
// 删除
export function clientlevelDelete(data) {
  return request({
    url: '/baseAdmin/customer-class/delete',
    method: 'post',
    data,
  })
}

// 客户等级排序
export function listSort(data) {
  return request({
    url: '/baseAdmin/customer-class/sort',
    method: 'post',
    data,
  })
}
// 添加 保存
export function clientlevelCreate(data) {
  return request({
    url: '/baseAdmin/customer-class/create',
    method: 'post',
    data,
  })
}
// 编辑详情
export function clientlevelDetail(data) {
  return request({
    url: '/baseAdmin/customer-class/detail',
    method: 'post',
    data,
  })
}

// 编辑保存
export function clientlevelUpdate(data) {
  return request({
    url: '/baseAdmin/customer-class/update',
    method: 'post',
    data,
  })
}

<template>
  <!-- 客户等级 zz201110-->
  <div class="wrapper">
    <!--  -->
    <el-row type="flex" class="row-bg btncls" justify="end">
      <el-col :span="2">
        <el-button @click="addEdit">添加</el-button>
      </el-col>
    </el-row>
    <!--  -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="客户等级"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <el-row :gutter="20">
          <el-col :span="16" :offset="2">
            <el-form-item label="*等级名称：" :label-width="formLabelWidth">
              <el-input v-model="form.class_name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="星级显示：" :label-width="formLabelWidth">
              <div style="display: flex; align-items: center">
                <el-rate v-model="form.class_level" @change="handlerrate" />
                <i
                  class="el-icon-circle-close"
                  @click="form.class_level = 0"
                ></i>
              </div>
              <p>
                点亮几颗星，相应等级门店就会显示几颗星（拜访客户/客户列表页面）
              </p>
            </el-form-item>
            <el-form-item label="拜访周期：" :label-width="formLabelWidth">
              <el-input
                v-model="form.visit_cycle"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="欠款预警金额：" :label-width="formLabelWidth">
              <el-input v-model="form.warn_money" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="欠款预警天数：" :label-width="formLabelWidth">
              <el-input v-model="form.warn_time" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="描述：" :label-width="formLabelWidth">
              <el-input v-model="form.class_remark" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="*状态：" :label-width="formLabelWidth">
              <el-radio-group v-model="form.is_closed">
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-if="saveType != 2"
          type="primary"
          @click="clientlevelCreate_fun(1)"
        >
          保存并新增
        </el-button>
        <el-button type="primary" @click="clientlevelCreate_fun(saveType)">
          保存
        </el-button>
        <el-button @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 表格-->
    <el-table
      v-loading="loading"
      stripe
      :data="tableData"
      border
      style="width: 100%"
    >
      <!-- <el-table-column type="index" label="排序" width="50"></el-table-column> -->
      <el-table-column prop="" label="排序" width="80" type="">
        <template #default="{ row }">
          <el-input
            v-model.trim="row.sort_order"
            @blur="blur_($event, row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="class_name"
        label="客户等级"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="class_remark"
        label="描述"
        width="180"
      ></el-table-column>
      <el-table-column prop="visit_cycle" label="拜访周期"></el-table-column>
      <el-table-column prop="warn_money" label="欠款预警金额"></el-table-column>
      <el-table-column prop="warn_time" label="欠款预警天数"></el-table-column>
      <el-table-column prop="is_closed_text" label="状态"></el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleEdit(scope.$index, scope.row)"
          >
            编辑
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    clientlevelList,
    clientlevelDelete,
    clientlevelCreate,
    clientlevelDetail,
    clientlevelUpdate,
    listSort,
  } from '@/api/clientLevel'
  export default {
    name: 'ClientLevel',
    components: {},
    data() {
      return {
        loading: true,
        listdata: {
          pageNo: 1,
          pageSize: 1000,
        },
        tableData: [],
        dialogTableVisible: false,
        dialogFormVisible: false,
        form: {
          class_level: 0,
          class_name: '',
          visit_cycle: '',
          warn_money: '',
          warn_time: '',
          class_remark: '',
          is_closed: 0,
        },
        formLabelWidth: '120px',
        saveType: 0, //2,编辑入口(保存) ，0，添加入口（保存）=[!2，不显示“保存并新增”]
      }
    },
    computed: {},
    created() {
      this.fetchData()
    },
    methods: {
      handlerrate() {},
      // 添加入口
      addEdit() {
        var that = this
        that.saveType = 0
        that.dialogFormVisible = true
        that.form = {
          class_name: '',
          visit_cycle: '',
          warn_money: '',
          warn_time: '',
          class_remark: '',
          is_closed: 0,
        }
      },
      // 获取表格数据
      async fetchData() {
        let { data, totalCount } = await clientlevelList(this.listdata)
        this.tableData = data
        this.loading = false
      },
      // 编辑入口
      handleEdit(index, row) {
        var that = this
        clientlevelDetail({ id: row.id }).then((d) => {
          console.log(d)
          if (d.code == 200) {
            that.form = d.data
          }
        })
        that.saveType = 2
        that.dialogFormVisible = true
      },
      handleDelete(index, row) {
        console.log(index, row)
        var that = this
        that
          .$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            clientlevelDelete({ id: row.id }).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          })
          .catch(() => {
            that.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      blur_(e, row) {
        // this.sort_()
        console.log(e, row)
        listSort({ id: row.id, sort_order: row.sort_order }).then((res) => {
          this.fetchData()
        })
      },
      clientlevelCreate_fun(types) {
        //添加入口1，保存并新增 0，保存
        // 编辑入口 2，保存
        var that = this
        if (that.form.class_name == '') {
          this.$alert('<p>请输入等级名称</p>', '提示', {
            dangerouslyUseHTMLString: true,
          })
        } else if (that.form.is_closed == 3) {
          this.$alert('<p>请选择状态</p>', '提示', {
            dangerouslyUseHTMLString: true,
          })
        } else {
          if (types == 2) {
            clientlevelUpdate(that.form).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.dialogFormVisible = false
              }
            })
          } else {
            clientlevelCreate(that.form).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                if (types == 1) {
                  that.addEdit()
                } else {
                  that.dialogFormVisible = false
                }
              }
            })
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    padding: 20px;
  }
  .btncls {
    margin: 10px 0;
  }
</style>
